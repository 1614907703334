.container_miniaturas_blog {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1.5rem;
}

.miniatura-blog {
	display: block;
	width: 100%;

	& .box_miniatura_img{
		display: block;
		width: 100%;
		padding: .5rem;
		background-color: white;
		border-radius: .2rem;
		border-left: solid 1px lighten($gray4, 10);
		border-right: solid 1px lighten($gray4, 10);
		border-top: solid 1px lighten($gray4, 10);

		& .miniatura-blog-img{
			border-radius: .2rem;
			display: block;
			width: 100%;
			overflow: hidden;
			padding-top: 65%;
			background-color: $gray4;
			position: relative;
			user-select: none;

			& img{
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

			}
		}
	}

	& .miniatura-blog-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: white;
		border-radius: .2rem;
		@include border-box;
		position: relative;
		border-left: solid 1px lighten($gray4, 10);
		border-right: solid 1px lighten($gray4, 10);
		border-bottom: solid 1px lighten($gray4, 10);

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& p{
				font-size: 1.1rem;
				font-weight: 500;
				color: $gray2;
				margin: 0;
				height: 1.5rem;
				max-height: 1.5rem;
				overflow: hidden;
			}

			& span{
				font-size: .85rem;
				font-weight: 600;
				color: $gray3;
			}
		}

		& .miniatura-blog-descricao{
			display: block;
			width: 100%;
			overflow: hidden;
			max-height: 4.5rem;
			height: 4.5rem;

			& p{
				color: $colorPrimary;
				font-size: .9rem;
				font-weight: 400;
			}

		}

		& .miniatura-btn-vermais{
			display: block;
			width: 100%;

			& p{
				font-size: .9rem;
				font-weight: 600;
				color: $gray2;
				padding: .5rem 1rem .5rem 0;
				background-color: transparent;
				border-radius: .2rem;
				margin: 0;
				transition: all .2s ease-in-out;
				width: fit-content;

				& i{
					font-size: .7rem;
					font-weight: 800;
				}

				&:hover{
					background-color: $colorTerciary;
					color: white;
					padding-left: 1rem;
				}
			}
		}
	}

}

@media (min-width: 1024px) and (max-width: 1366px){
	.container_miniaturas_blog {
		gap: 1rem;
	}
}

@media (max-width: 1023px){
	.container_miniaturas_blog {
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}