.title_condomi {
	display: block;
	width: 100%;

	& h2 {
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $gray1;
	}

	& .under_p {
		text-align: center;
		width: 25%;
		height: 1px;
		margin: auto;
		background-color: $gray4;
		border-radius: 2rem;
	}
}

.title_condomi2 {
	display: block;
	width: 100%;

	& h2 {
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $gray1;
	}

	& .under_p {
		text-align: center;
		width: 25%;
		height: 1px;
		margin: auto;
		background-color: $gray4;
		border-radius: 2rem;
	}
}

.section_infraestruturas {
	background: linear-gradient(to bottom, white 9%, $colorPrimary 9%);
	padding: 2rem 0;
	width: 100%;
	background-image: url('/jnh/background/banner_infras.jpg');
	background-repeat: no-repeat;
	background-size: 100% 91%;
	background-position: bottom;

	& .title_section_infras {
		background-color: white;
		box-shadow: 0 0 10px 0 rgba($gray1, .2);
		padding: 2.5rem 5rem;
		width: fit-content;
		margin: auto;
		border-radius: $borderRadius;

		& .title {
			text-align: center;
			display: block;
			width: 100%;

			& h2 {
				font-size: 1.75rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
				padding-bottom: .5rem;
			}

			& .under_p {
				text-align: center;
				width: 30%;
				height: 1px;
				margin: auto;
				background-color: $colorTerciary;
				border-radius: 2rem;
			}
		}
	}

	& .container_infras {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
		width: 100%;

		& a {
			& .miniatura_infraestruturas {
				width: 100%;
				position: relative;
				border-radius: $borderRadius;

				& .img {
					width: 100%;
					display: block;
					padding-top: 60%;
					background-color: transparent;
					overflow: hidden;
					position: relative;
					border-radius: $borderRadius;
					box-shadow: 0 0 10px 2px rgba($gray2, .3);

					& img {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						filter: brightness(80%);
						transition: all .2s ease-in-out;
						user-select: none;
						-webkit-user-drag: none;
						object-fit: cover;
					}
				}

				& .texto_infraestruturas {
					display: block;
					width: fit-content;
					position: absolute;
					top: 90%;
					left: 50%;
					transform: translate(-50%, -90%);
					pointer-events: none;
					background-color: white;
					border: solid 1px lighten($colorPrimary, 25);
					border-radius: $borderRadius;
					padding: .5rem 2rem;
					font-weight: 600;
					color: $gray1;
					font-size: 1.1rem;
					z-index: 1;
					line-height: none;
					text-align: center;
					white-space: nowrap;
					transition: border-radius .2s ease-in-out;
				}
			}

			&:hover,
			&:focus {
				& .miniatura_infraestruturas {
					& .img {
						& img {
							filter: brightness(100%);
							transform: translate(-50%, -50%) scale(1.05);
						}
					}
				}
			}
		}
	}
}

.blog_section {
	display: block;
	width: 100%;

	& h1 {
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $gray1;
	}

	& h2 {
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $gray1;
	}

	& .under_p {
		text-align: center;
		width: 25%;
		height: 1px;
		margin: auto;
		background-color: $gray4;
		border-radius: 2rem;
	}

	& p {
		text-align: center;
		font-size: 1rem;
		color: $gray2;
		font-weight: 400;
	}
}

.section_servicos {
	width: 100%;
	display: block;
	background: white;

	& .container_servicos {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;

		& a {
			width: 100%;
			padding: 1rem;
			border-radius: .3rem;
			transition: border-color .2s ease-in-out;
			border: solid 1px lighten($gray4, 10);
			background-color: white;

			& .bloco_servico {
				width: 100%;
				display: block;
				text-align: left;

				& .img {
					width: 50px;
					height: 50px;
					display: block;
					margin: 0;

					& img {
						text-align: left;
						width: 50px;
						height: 50px;
					}
				}

				& .descricao {
					width: block;
					display: block;
					position: relative;

					& h2 {
						color: $colorPrimary;
						font-size: .9rem;
						font-weight: 600;
						text-align: left;
						line-height: normal;
					}

					& p {
						padding: 0;
						color: lighten($gray2, 5);
						font-size: .9rem;
						font-weight: 400;
						text-align: left;
						transition: all .15s;
					}
				}
			}

			&:hover {
				border-color: $colorTerciary;
			}
		}
	}
}

.section_sobre {
	background: linear-gradient(120deg, white 65%, $colorPrimary 65%);
	background-color: white;
	display: block;
	width: 100%;

	& .container_sobre {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 2rem;
		align-items: center;

		& .conteudo {
			width: 80%;
			margin: 0 auto;

			& h3 {
				font-size: 1.3rem;
				font-weight: 600;
				color: $colorTerciary;
				margin: 0;
			}

			& h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: $gray1;
			}

			& p {
				font-size: 1rem;
				font-weight: 400;
				color: $gray2;
			}

			& strong {
				font-size: 1rem;
				font-weight: 400;
				color: $colorPrimary;
			}

			& .btn_vermais {
				width: 100%;
				margin: 0;

				& a {
					width: fit-content;
					display: block;
					margin: 0;

					& p {
						background-color: white;
						color: $gray1;
						font-size: 1rem;
						font-weight: 600;
						padding: .3rem 1.5rem;
						border-radius: $borderRadius;
						transition: border-color .2s ease-in-out;
						border: solid 1px lighten($gray4, 10);

						& i {
							color: $colorTerciary;
							font-size: 1.2rem;
							vertical-align: middle;
						}

						&:hover {
							border-color: $colorTerciary;
						}
					}
				}
			}

			& .infos_sobre {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem 3rem;

				& .info_imob {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					& .img {
						width: calc(256px / 6.5);
						height: calc(256px / 6.5);
						display: inline-block;
						vertical-align: middle;

						& img {
							width: calc(256px / 6.5);
							height: calc(256px / 6.5);
						}
					}

					& .descricao {
						width: 100%;

						& .title {
							margin: 0;
							font-size: .9rem;
							color: $gray1;
							font-weight: 600;
							margin: 0;
						}

						& p {
							padding-left: 1rem;
							color: $gray2;
							font-size: .85rem;
							font-weight: 400;
							margin: 0;
						}

						& a {
							width: fit-content;
							display: block;
							margin: 0;

							& p {
								color: $gray2;
								font-size: .85rem;
								font-weight: 400;
								margin: 0;
								background-color: transparent;
							}
						}
					}
				}
			}

		}

		& .imagem_sobre {
			width: 100%;
			margin: 0;

			& img {
				border-radius: $borderRadius;
				width: 700px;
				height: 466px;
				-webkit-user-drag: none;
			}
		}
	}
}

.bg_busca_interna {
	background-color: white;
	display: block;
	width: 100%;

	& h2 {
		text-align: center;
		color: $gray1;
		font-size: 1.8rem;
		font-weight: 400;
	}


	& .container_filtros_busca_interna {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2rem 1rem;

		& .grid_items {
			width: 100%;

			& .titulo-formulario {
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

#ux_to_top {
	position: relative;
	top: -100px;
	left: -90%;
	z-index: 999999;
}

.botao_voltar {
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: $borderRadius;
	padding: .3rem 1rem;
	color: $gray1 !important;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	& i {
		color: $colorTerciary;
	}

	&:hover {
		border-color: $colorTerciary;
	}
}

.title_contato {
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorPrimary;
	vertical-align: middle;

	& i {
		color: $colorTerciary;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {

	.section_infraestruturas {
		background-size: 100% 89%;

		& .title_section_infras {
			padding: 2rem 5rem;

			& .title {
				& h2 {
					font-size: 1.6rem;
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(120deg, white 68%, $colorPrimary 68%);

		& .container_sobre {
			gap: 1rem;

			& .conteudo {
				width: 85%;

				& p {
					font-size: .9rem;
				}

				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;

							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					gap: 2rem;

					& .info_imob {

						& .img {
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);

							& img {
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}

						& .descricao {
							& p {
								font-size: .85rem;
							}

							& a {
								& p {
									font-size: .85rem;
								}
							}
						}
					}
				}
			}

			& .imagem_sobre {
				& img {
					width: calc(700px / 1.3);
					height: calc(466px / 1.3);
				}
			}
		}
	}
}


@media all and (max-width: 1023px) {

	.title_condomi {
		& h2 {
			font-size: 1.5rem;
		}

		& .under_p {
			width: 30%;
		}
	}

	.title_condomi2 {
		& h2 {
			font-size: 1.5rem;
		}

		& .under_p {
			width: 30%;
		}
	}

	.blog_section {
		& h1 {
			font-size: 1.5rem;
		}
	
		& h2 {
			font-size: 1.5rem;
		}

		& .under_p {
			width: 30%;
		}
	
		& p {
			font-size: .9rem;
		}
	}

	.bg_busca_interna {
		& h2 {
			font-size: 1.4rem;
		}

		& .container_filtros_busca_interna {
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.section_infraestruturas {
		background: linear-gradient(to bottom, white 4%, $colorPrimary 4%);
		padding: 2rem 0;
		width: 100%;
		background-image: url('/jnh/background/banner_infras.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;

		& .title_section_infras {
			padding: 1.5rem;
			width: 90%;
			margin: auto;

			& .title {
				& h2 {
					font-size: 1.2rem;
				}
			}
		}


		& .container_infras {
			grid-template-columns: 1fr;
			padding: 0;

			& a {
				& .miniatura_infraestruturas {
					& .texto_infraestruturas {
						padding: .2rem .5rem;
						font-size: 1rem;
					}
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(165deg, $colorPrimary 20%, white 20%);

		& .container_sobre {
			display: flex;
			flex-direction: column;
			flex-direction: column-reverse;
			gap: 1rem;
			grid-template-columns: none;


			& .conteudo {
				width: 100%;

				& h3 {
					font-size: 1.2rem;
				}

				& h2 {
					font-size: 1.3rem;
				}

				& p {
					font-size: .9rem;
				}

				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;

							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					grid-template-columns: 1fr;
					gap: 2rem;

					& .info_imob {

						& .img {
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);

							& img {
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}

						& .descricao {
							& p {
								font-size: .85rem;
							}

							& a {
								& p {
									font-size: .85rem;
								}
							}
						}
					}
				}
			}

			& .imagem_sobre {
				text-align: center;

				& img {
					width: calc(700px / 2.3);
					height: calc(466px / 2.3);
					max-width: 90vw;
					margin: auto;
				}
			}
		}
	}

	.section_servicos {
		& .container_servicos {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;

			& a {
				& .bloco_servico {
					& .descricao {
						& h2 {
							font-size: .9rem;
						}
					}
				}
			}
		}
	}

	#ux_to_top {
		left: 15%;
		top: -80px;
	}
}