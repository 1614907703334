.section_depoimentos {
	display: block;
	width: 100%;
	background-color: lighten($gray5, 2);

	.title_depoimentos {
		display: block;
		width: 100%;

		& h2 {
			text-align: center;
			font-size: 1.75rem;
			font-weight: 600;
			color: $gray1;
		}

		& .under_p {
			text-align: center;
			width: 25%;
			height: 1px;
			margin: auto;
			background-color: $gray4;
			border-radius: 2rem;
		}
	}

	.container_miniaturas {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 2rem;

		& .miniatura_depoimento{
			display: block;
			width: 100%;
			border: solid 1px lighten($gray4, 10);
			background-color: white;
			border-radius: .2rem;
			padding: 1rem;
			box-sizing: border-box;
		
			& .imagem_nome{
				display: flex;
				justify-content: flex-start;
				align-items: center;

				& .img_dep {
					width: calc(960px / 17);
					height: calc(960px / 17);
					margin: 0;
					border-radius: .2rem;
					background-color: $gray4;
					position: relative;
					overflow: hidden;

					& img{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						object-fit: cover;
					}
				}
		
				& .nome{
					font-size: 1rem;
					font-weight: 600;
					color: $gray1;
					line-height: 1.2rem;
					margin: 0;
					padding-left: 1rem;
					
					& .cargo{
						font-size: .85rem;
						font-weight: 400;
						color: $gray2;
						margin: 0;
					}
				}
			}
		
			& .depoimento{
				font-size: .9rem;
				font-weight: 400;
				color: $gray2;
				min-height: 3.7rem;
				max-height: 3.7rem;
				overflow: hidden;
			}
		
			& .vermais_btn{
				display: block;
				width: fit-content;
				margin-right: 0;
				
				& .vermais{
					font-size: .85rem;
					font-weight: 600;
					color: $gray2;
					user-select: none;
					cursor: pointer;
					text-align: right;
					transition: all .2s ease-in-out;
					padding: .2rem 1rem;
					border-radius: .2rem;
					margin: 0;
					line-height: inherit;
					
					&:hover{
						color: white;
						background-color: $colorTerciary;
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.section_depoimentos {
		.container_miniaturas {
			gap: 1rem;
	
			& .miniatura_depoimento{
				& .imagem_nome{
					& .img_dep {
						width: calc(960px / 20);
						height: calc(960px / 20);
					}
			
					& .nome{
						font-size: .9rem;
						line-height: 1.1rem;
						
						& .cargo{
							font-size: .8rem;
						}
					}
				}
			
				& .depoimento{
					font-size: .85rem;
					min-height: 3.5rem;
					max-height: 3.5rem;
				}
			}
		}
	}
}

@media (max-width: 1023px){
	.section_depoimentos {
		.title_depoimentos {
			& h2 {
				font-size: 1.5rem;
			}
	
			& .under_p {
				width: 30%;
			}
		}
	
		.container_miniaturas {
			grid-template-columns: 1fr;
			gap: 2rem;
	
			& .miniatura_depoimento{
				& .imagem_nome{
					& .img_dep {
						width: calc(960px / 20);
						height: calc(960px / 20);
					}
			
					& .nome{
						font-size: .9rem;
						line-height: 1.1rem;
						
						& .cargo{
							font-size: .8rem;
						}
					}
				}
			}
		}
	}
}