.imo_telefones_btn{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 30px !important;
	z-index: 9999;
       
    & #whatsapp_lista_telefones{
        position: fixed;
        bottom: 2rem;
        right: 8rem;
        & p{
            background-color: white;
            border: solid 1px lighten($gray4, 10);
            box-shadow: 0 0 10px 0 rgba($gray2, .15);
            padding: .5rem 1.5rem;
            font-size: 1rem;
            font-weight: 500;
            color: $gray1;
            border-radius: $borderRadius;
            text-align: center;
            user-select: none;
            cursor: pointer;
            &:hover{
                border-color: $gray4;
            }
        }
        
    }

    & #wpp_pulsante{
        width: 100%;
		display: block;
		background-color: #25d366;
		margin: auto;
        width: 4.5rem;
        height: 4.5rem;
		border-radius: 50%;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		/* -webkit-animation: pulse 1.5s infinite; */
        position: relative;
        cursor: pointer;

        & i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 2rem;
            font-weight: 500;
        }
    }
}

.pai_section_dialog{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 8rem !important;
	z-index: 9997;
    
    & .section_dialog{
        display: block;
        width: 100%;
        padding: 2rem;
        border-radius: $borderRadius;
        background-color: white;
        position: relative;
        border: solid 1px lighten($gray4, 10);
        box-shadow: 0 0 10px 0 rgba($gray3, .15);

        & .btn_fechar_form_zap{
            position: absolute;
            top: -1rem;
            right: -1rem;
            background-color: #25d366;
            border-radius: 50%;
            padding: .5rem;
            border: solid 3px white;
            transition: all .15s;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;
            & i{
                color: white;
                text-align: center;
                padding: 0 0 0 3px;
            }
            &:hover{
                background-color: white;
                border: solid 3px #25d366;
                & i{
                    color: #25d366;
                }
            }
        }

        & .dialog_wpp{
            display: block;
            width: 100%;
            
            & h2{
                text-align: center;
                font-size: 1.2rem;
                font-weight: 500;
                color: $gray1;
                margin: 0;
                padding-bottom: .5rem;
            }

            & .under_p{
                text-align: center;
                width: 4rem;
                height: 2px;
                margin: auto;
                background-color: #25d366;
            }

            & .inputs_wpp{
                display: block;
                width: 100%;

                & .inputs_dados{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 1rem 0 0;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: .8rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & input{
                        border: solid 1px lighten($gray4, 10);
                        border-radius: $borderRadius;
                        width: 100%;
                        display: block;
                        padding: .7rem 1rem;
                    }
        
                }
                & .input_msg{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 0 0 1rem;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: .8rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & textarea{
                        border: solid 1px lighten($gray4, 10);
                        border-radius: $borderRadius;
                        width: 100%;
                        height: 120px;
                        display: block;
                        padding: .7rem 1rem;
                    }

                    & button{
                        width: 100%;
                        border: none;
                        border-radius: $borderRadius;
                        background-color: #25d366;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                        padding: .4rem 0;
                        cursor: pointer;

                        & i{
                            color: white;
                            font-size: 1.5rem;
                            font-weight: 400;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1023px){
    .imo_telefones_btn{
        position: fixed !important;
        bottom: 2rem !important;
        right: 1rem !important;
        z-index: 9997;
           
        & #whatsapp_lista_telefones{
            padding: .5rem;
            position: fixed;
            bottom: 2rem;
            right: 6rem;
        }
    }

    .pai_section_dialog{
        position: fixed !important;
        bottom: 2.5rem !important;
        right: 2.5rem !important;
        z-index: 9997;
        
        & .section_dialog{
            width: 80vw;
            height: fit-content;
            & .btn_fechar_form_zap{
                position: absolute;
                top: -1rem;
                right: -1rem;
                background-color: #25d366;
                border-radius: 50%;
                padding: .5rem;
                border: solid 3px white;
                transition: all .15s;
                height: 2.5rem;
                width: 2.5rem;
                cursor: pointer;
                & i{
                    color: white;
                    text-align: center;
                    padding: 0 0 0 3px;
                }
                &:hover{
                    background-color: white;
                    border: solid 3px #25d366;
                    & i{
                        color: #25d366;
                    }
                }
            }
    
            & .dialog_wpp{
                display: block;
                width: 100%;
                padding: 0;
                padding-right: 10px;
                @include border-box;
                height: fit-content;
                min-height: 50vh;
                overflow: auto;

                &::-webkit-scrollbar{width: 3px; height: 10px;}
                &::-webkit-scrollbar-track-piece{background-color: $gray5;}
                &::-webkit-scrollbar-thumb:vertical{height: 10px; background-color: $gray4;}
                &::-webkit-scrollbar-thumb:horizontal{width: 2px; background-color: $gray4;}
                &::-ms-clear {width: 0; height: 0;}
                &::-ms-reveal {width: 0; height: 0;}

                & h2{
                    font-size: 1.2rem;
                }

                & .inputs_wpp{
                    display: block;
                    width: 100%;

                    & .inputs_dados{
                        width: 100%;
                        display: block;
                        margin: 0 0 1rem 0;
                        padding: 0;
                    }

                    & .input_msg{
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding:0;

                        & textarea{
                            height: 80px;
                        }
                    }
                }
            }
        }
    }
}