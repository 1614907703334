/* SCROLL-BAR */
::-webkit-scrollbar{width: 5px; height: 5px;}
::-webkit-scrollbar-track-piece{background-color: $gray5;}
::-webkit-scrollbar-thumb:vertical{height: 5px; background-color: $gray4;}
::-webkit-scrollbar-thumb:horizontal{width: 5px; background-color: $gray4;}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.bg_section_imoveis{
	background-color: white;
}

.btn_ver_mais{
	text-align: center;
	width: fit-content;

	& p{
		color: $gray1;
		font-weight: 500;
		font-size: 1rem;
		transition: border-color .2s ease-in-out;
		border: solid 1px lighten($gray4, 10);
		background-color: white;
		padding: .5rem 1.5rem;
		line-height: 1rem;
		border-radius: $borderRadius;
		
		& i {
			vertical-align: middle;
			color: $colorTerciary;
			font-size: 1.2rem;
			margin: 0;
			line-height: 1rem;
		}

		&:hover{
			border-color: $colorTerciary;
		}
	}
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'Open Sans', sans-serif;
	background-color: white;
	color: $gray2;
	font-weight: 500;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

.politica_formularios{
	width: 100%;
	display: block;
	
	& p{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
		& a{
			color: $colorPrimary;
			font-weight: 500;
			font-size: .8rem;
			user-select: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	& label{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
	}

	& input{
		width: fit-content !important;
	}
}

.btn_enviar_form{
	width: fit-content;
	display: block;
	background-color: $colorTerciary;
	color: white;
	font-weight: 600;
	font-size: 1rem;
	border: solid 1px $colorTerciary;
	border-radius: $borderRadius;
	cursor: pointer;
	transition: all .2s ease-in-out;
	padding: .5rem 2rem;

	&:hover{
		background-color: darken($colorTerciary, 2);
	}
}

h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $gray2;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1 !important;
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 400;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.site-space-2-5 {
	display: table;
	width: 100%;
	height: 2.5rem; 
}

.container-fixo {
	position: fixed !important;
	bottom: 30px !important;
	right: 0 !important;
	z-index: 9997;
}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}
.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.creccss {
	margin: 0;

	& select {
		text-align: center;
		color: $gray2 !important;
		border: solid 1px lighten($gray4, 10);
		border-radius: $borderRadius;
		font-size: .9rem;
		font-weight: 600;
		width: 100%;
		height: auto;
		padding: .5rem 0;
		background-color: white;
		cursor: pointer;
		transition: border-color .2s ease-in-out;
		box-sizing: border-box;
		margin: 0;
		
		&:hover{
			border-color: $colorTerciary;
		}
	}
}

.btns_dialog_video {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	
	& button {
		width: 100%;
		font-weight: 600 !important;

		& i {
			color: $colorTerciary;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){
	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){
	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){

	html{
		font-size: 3.75vw;
	}
}

@media all and (max-width:1023px){

	.creccss {
		width: 60%;
	}

	.container-fixo {
		position: fixed !important;
		bottom: 30px !important;
		right: 30px !important;
		z-index: 9997;
	}

	.splide__pagination {
		bottom: 7.5rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {
	.splide__arrow--next {
	right: 2rem !important;
	}

	.splide__arrow--prev {
	left: 2rem !important;
	}
}