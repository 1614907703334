.nav {
	display: block;
	width: 35vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99991;
	padding: 0;
	box-shadow: -5px 0 10px -5px rgba(black, .2);

	& .container_nav {
		width: 100%;
		height: 100vh;
		display: grid;
		grid-template-columns: 1.5fr 2.5fr !important;
		gap: 0;

		& .nav_left {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			background-color: $gray5;
			padding: 2rem 1rem;

			& h2 {
				font-size: 2rem;
				font-weight: 800;
				color: lighten($gray3, 30);
				text-transform: uppercase;
				margin: 0;
			}

			& .nav_redes_sociais {
				margin: 0;
				display: block;
				width: 100%;

				& .contatos {
					display: flex;
					flex-direction: column;
					gap: .5rem;

					& .nome_corretor {
						color: $gray1;
						font-size: 1.1rem;
						font-weight: 600;
						line-height: 1.75rem;
					}

					& a {
						& p {
							margin: 0;
							font-size: .9rem;
							font-weight: 400;
							color: $gray2;
							border-left: solid 1px transparent;
							transition: all .25s ease-in-out;
							padding: .5rem 0;

							& i {
								font-size: 1rem;
								vertical-align: middle;
							}

							&:hover {
								padding-left: .5rem;
								border-color: $colorTerciary;
								background-color: darken($gray5, 5);
							}
						}
					}
				}

				& .redessociais {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;

					& a {
						& i {
							font-size: 1.2rem;
							padding: .5rem;
							color: $gray2;
							transition: color .2s ease-in-out;

							&:hover {
								color: $colorTerciary;
							}
						}
					}
				}
			}
		}

		& .nav_right {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			padding: 3rem 1rem;
			position: relative;

			& button {
				background-color: transparent;
				border: none;
				cursor: pointer;
				position: absolute;
				top: 1%;
				right: 2%;

				& i {
					line-height: inherit;
					color: $colorTerciary;
					font-size: 2rem;
				}
			}

			& ul {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: .5rem;

				& li {
					& a {
						& .nav-menu {
							color: $gray1;
							font-size: 1rem;
							font-weight: 400;
							margin: 0;
							padding: .5rem .5rem;
							@include border-box;
							transition: all .25s ease-in-out;
							border-left: solid 1px transparent;

							&:hover {
								border-color: $colorTerciary;
								padding-left: 1rem;
								background-color: $gray5;
							}
						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {
	.nav {
		width: 45vw;

		& .container_nav {
			& .nav_left {
				& .nav_redes_sociais {
					& .contatos {
						gap: .3rem;

						& .nome_corretor {
							font-size: 1rem;
						}

						& a {
							& p {
								font-size: .85rem;

								& i {
									font-size: .9rem;
								}
							}
						}
					}

					& .redessociais {
						& a {
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}
			}

			& .nav_right {
				& button {
					right: 3%;
				}

				& ul {
					gap: .3rem;

					& li {
						& a {
							& .nav-menu {
								font-size: .9rem;
							}
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px) {
	.nav {
		width: 100%;
		overflow: auto;
		height: 100vh;

		& .container_nav {
			width: 100%;
			height: 100vh;
			display: grid;
			grid-template-areas: "nav_right" "nav_left";
			grid-template-columns: 1fr !important;
			grid-template-rows: 1fr 1fr !important;
			gap: 0;
	
			& .nav_left {
				grid-area: nav_left;

				& h2 {
					display: none;
				}
			}

			& .nav_right {
				grid-area: nav_right;
			}
		}
	}
}