.section_imobiliaria{
    display: block;
    width: 100%;

    & h1{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & h2{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & p{
        font-size: .9rem;
        font-weight: 400;
        color: black;
    }
}
