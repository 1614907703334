.footer1 {
	/* box-shadow: 0 0 15px 1px rgba($gray2, .1); */
	border-top: solid 3px $colorTerciary;
	width: 100%;
	display: block;
	background-color: $colorPrimary;
	
	& .top_footer {
		background-color: white;
		display: block;
		width: 100%;
		margin: 0;

		& .container_top_footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			
			& .logo_footer {
				margin: 0;
				width: 100%;

				& a {
					width: calc(350px / 1.5);
					height: calc(200px / 1.5);
					display: block;
					text-align: center;

					& img{
						width: calc(350px / 1.5);
						height: calc(200px / 1.5);
						user-select: none;
						-webkit-user-drag: none;
					}
				}

			}

			& .bloco_redessociais_footer{
				width: 100%;
				text-align: right;
				margin: 0;
				
				& .contato_top_footer {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: .5rem;

					& .item_tf {
						margin: 0;

						& a {
							& p {
								margin: 0;
								font-size: .9rem;
								font-weight: 400;
								color: $gray1;
								
								& i {
									font-size: 1rem;
									color: $gray2;
									vertical-align: middle;
								}
							}
						}
					}
				}

				& .redes_sociais_top_footer {
					display: flex;
					flex-direction: row;
					gap: 1rem;
					align-items: center;
					justify-content: flex-end;

					& .item_rs {
						margin: 0;
						& a {
							& i {
								font-size: 1.2rem;
								color: $gray2;
								transition: color .2s ease-in-out;
							}

							&:hover{
								& i {
									color: $colorTerciary;
								}
							}
						}
					}
				}
			}
		}
	}

	& .ContainerFooter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: start;
		gap: 2rem;
		width: 100%;

		& .BoxItemFooter{
			width: 100%;
			display: block;
			margin: 0;

			& .ContentItemFooter{
				display: flex;
				flex-direction: column;
				gap: 1rem;

				& li{
					& h3{
						font-size: 1.1rem;
						color: white;
						font-weight: 600;
						margin: 0;
						padding: 0;
						user-select: none;
					}
					
					& a{
						width: fit-content;
						display: block;
						
						& p{
							font-size: .9rem;
							color: white;
							margin: 0;
							padding: 0;
							line-height: normal;
							transition: color .2s ease-in-out;
							user-select: none;
							
							& i{
								color: white;
								font-weight: 400;
							}
						}
					}

					& p{
						font-size: .9rem;
						color: white;
						margin: 0;
						padding: 0;
						line-height: normal;
						transition: color .2s ease-in-out;
						user-select: none;
						
						& i{
							color: white;
							font-weight: 400;
						}
					}

					& .endereco_footer {
						max-width: 80%;
					}
				}
			}
		}
	}

	& .ContainerInferiorFooter{
		width: 100%;
		
		& .ItemContainerInferior{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;

			& p{
				margin: 0;
				font-size: .85rem;
				font-weight: 400;
				color: white;
				user-select: none;

				& a{
					& span{
						color: white;
						font-weight: 600;
						font-size: .85rem;
						transition: color .2s ease-in-out;
						text-shadow: 0px 0px 1px transparent;
					}

					&:hover{
						& span{
							color: white;
							text-shadow: 0px 0px 1px $colorTerciary;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.footer1 {
		& .ContainerFooter {
			& .BoxItemFooter{
				& .ContentItemFooter{
					& li{
						& .endereco_footer {
							max-width: 90%;
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1 {

		& .top_footer {
			& .container_top_footer {
				flex-direction: column;
				
				& .logo_footer {
					margin-bottom: 3rem;
					& a {
						margin: 0 auto;
					}
	
				}
	
				& .bloco_redessociais_footer{
					text-align: left;
					
					& .contato_top_footer {
						align-items: flex-start;
						gap: 1rem;
	
						& .item_tf {
							& a {
								& p {
									font-size: .95rem;
	
									& i {
										font-size: 1.1rem;
									}
								}
							}
						}
					}
	
					& .redes_sociais_top_footer {
						gap: 1.5rem;
						justify-content: flex-start;
	
						& .item_rs {
							& a {
								& i {
									font-size: 1.4rem;
									color: $colorPrimary;
								}
							}
						}
					}
				}
			}
		}

		& .ContainerFooter {
			flex-direction: column;
			gap: 5rem;
	
			& .BoxItemFooter{
				& .ContentItemFooter{
					& li{
						& h3{
							font-size: 1.1rem;
						}
						
						& a{
							& p{
								font-size: 1rem;
							}
						}
	
						& p{
							font-size: 1rem;
						}

						& .endereco_footer {
							max-width: 100%;
						}
					}
				}
			}
		}
	
		& .ContainerInferiorFooter{
			& .ItemContainerInferior{
				& p{
					font-size: .9rem;
					text-align: center;
					line-height: 1.7rem;

					& a{
						& span{
							font-size: .9rem;
						}
					}
				}
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}